<h1 class="mb-4 text-3xl font-medium" i18n>Personal Details</h1>

<app-segments
  [activeValue]="page"
  (clicked)="page = $event"
  [items]="[
  {
    icon: 'details',
    value: 'details',
    title: 'Personal Details'
  },
  {
    icon: 'password',
    value: 'password',
    title: 'Password'
  },
]"
/>

<div *ngIf="!loading && user; else showLoadingSpinner" class="mt-8">
  <div *ngIf="page === 'details' && user">
    <div class="relative max-w-sm">
      <p class="text-sm font-light text-gray-600">First name</p>

      <p
        *ngIf="!editing.firstName"
        class="mb-5 mt-1 flex items-center text-xl text-black"
      >
        {{ user.firstName }}
        <ng-container
          *ngIf="userStateLoading && user.firstName !== userForm.value.firstName"
        >
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <app-input
        *ngIf="editing.firstName"
        [form]="userForm"
        controlName="firstName"
      />

      <app-button
        *ngIf="!editing.firstName"
        [disabled]="userStateLoading && user.firstName !== userForm.value.firstName"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('firstName')"
        (keydown.enter)="editMode('firstName')"
      />

      <div *ngIf="editing.firstName" class="mb-4 mt-2">
        <app-button
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          [disabled]="!userForm.value.firstName || userForm.value.firstName === user.firstName"
          text="Save"
          type="submit"
          classes="py-2 px-6  ml-3"
          (click)="updateFirstName()"
          (keydown.enter)="updateFirstName()"
        />
      </div>
    </div>

    <div class="relative max-w-sm">
      <p class="text-sm font-light text-gray-600">Last name</p>

      <p
        *ngIf="!editing.lastName"
        class="mb-5 mt-1 flex items-center text-xl text-black"
      >
        {{ user.lastName }}
        <ng-container
          *ngIf="userStateLoading && user.lastName !== userForm.value.lastName"
        >
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <app-input
        *ngIf="editing.lastName"
        [form]="userForm"
        controlName="lastName"
      />

      <app-button
        *ngIf="!editing.lastName"
        [disabled]="userStateLoading && user.lastName !== userForm.value.lastName"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('lastName')"
        (keydown.enter)="editMode('lastName')"
      />

      <div *ngIf="editing.lastName" class="mb-4 mt-2">
        <app-button
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
          (keydown.enter)="clearEditMode()"
        />
        <app-button
          [disabled]="!userForm.value.lastName || userForm.value.lastName === user.lastName"
          text="Save"
          type="submit"
          classes="py-2 px-6  ml-3"
          (click)="updateLastName()"
          (keydown.enter)="updateLastName()"
        />
      </div>
    </div>

    <div class="relative max-w-sm">
      <p class="text-sm font-light text-gray-600">Phone number</p>

      <p
        *ngIf="!editing.phoneNumber"
        class="mb-5 mt-1 flex items-center text-xl text-black"
      >
        {{ user.phoneNumber }}
        <ng-container
          *ngIf="userStateLoading && user.phoneNumber !== userForm.value.phoneNumber"
        >
          <div class="ml-4">
            <app-loading size="h-4 w-4" />
          </div>
        </ng-container>
      </p>

      <!-- <app-input
        *ngIf="editing.phoneNumber"
        [form]="userForm"
        controlName="phoneNumber"
      />

      <app-button
        *ngIf="!editing.phoneNumber"
        [disabled]="userStateLoading && user.phoneNumber !== userForm.value.phoneNumber"
        icon="edit"
        iconFill="blue"
        iconHeight="18px"
        classes="p-3 absolute top-1 right-0"
        (click)="editMode('phoneNumber')"
      />

      <div *ngIf="editing.phoneNumber" class="mb-4 mt-2">
        <app-button
          text="Cancel"
          classes="py-2 px-6"
          (click)="clearEditMode()"
        />
        <app-button
          [disabled]="!userForm.value.phoneNumber || userForm.value.phoneNumber === user.phoneNumber"
          text="Save"
          type="submit"
          classes="py-2 px-6  ml-3"
          (click)="updatePhoneNumber()"
        />
      </div> -->
    </div>

    <div>
      <p class="text-sm font-light text-gray-600">Email address</p>

      <p class="mb-5 mt-1 text-xl text-black">{{ user.email }}</p>
    </div>

    <div>
      <p class="text-sm font-light text-gray-600">Date created</p>

      <p class="mb-5 mt-1 text-xl text-black">{{ user.createdAt }}</p>
    </div>

    <!-- Asked to comment out by Chris - 24/09/2024 -->
    <!-- <div>
      <p class="text-gray-600 text-sm font-light">Created by</p>

      <p class="mb-5 mt-1 text-xl text-black">{{ user.createdBy }}</p>
    </div>

    <div>
      <p class="text-gray-600 text-sm font-light">Status</p>

      <p class="mb-5 mt-1 text-xl text-black">{{ user.status | titlecase }}</p>
    </div> -->
  </div>

  <div *ngIf="page === 'password'" class="max-w-96">
    <form (submit)="updatePassword($event)">
      <app-password-input
        [form]="passwordForm"
        controlName="existingPassword"
        i18n-label
        label="Enter existing password"
        name="existingPassword"
      />

      <br />
      <app-password-input
        [form]="passwordForm"
        controlName="password"
        i18n-label
        label="Enter new password"
        name="password"
      />

      <br />

      <app-password-input
        [form]="passwordForm"
        controlName="confirmPassword"
        i18n-label
        label="Confirm new password"
        name="confirmPassword"
      />

      <div class="mt-10">
        <app-button
          type="submit"
          [disabled]="loading || !passwordForm.valid"
          i18n-text
          text="Save Changes"
          classes="w-full"
        />

        <app-button
          class="mt-4"
          type="button"
          [disabled]="loading"
          i18n-text
          text="Cancel"
          classes="w-full px-4 mt-6"
        />
      </div>
    </form>
  </div>
</div>

<ng-template #showLoadingSpinner>
  <div class="mt-20 flex items-center justify-center">
    <app-loading />
  </div>
</ng-template>

<app-password-updated-modal
  [show]="showPasswordUpdatedModal"
  (closed)="passwordUpdatedModalClosed()"
/>
