import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ButtonComponent } from '../../../components/button/button.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { InputComponent } from '../../../components/input/input.component';
import { LoadingComponent } from '../../../components/loading/loading.component';
import { SegmentsComponent } from '../../../components/segments/segments.component';
import { UserStatus } from '../../../interfaces/status.interface';
import { UserSummary } from '../../../interfaces/user.interface';
import { CognitoActions } from '../../../store/actions/cognito.actions';
import { UserActions } from '../../../store/actions/user.actions';
import { selectCognitoPending } from '../../../store/selectors/cognito.selectors';
import { selectUser } from '../../../store/selectors/user.selector';
import { formatDate } from '../../../utils/date';
import { confirmPasswordValidator } from '../../../validators/confirm-password.validator';
import { PasswordValidators } from '../../../validators/password.validator';
import { PasswordInputComponent } from '../../auth/components/password-input/password-input.component';
import { PasswordUpdatedModalComponent } from './password-updated/password-updated.component';

export const PERSONAL_DETAILS_PAGE_URL = 'personal-details';

@Component({
  standalone: true,
  templateUrl: './personal-details.page.html',
  imports: [
    CommonModule,
    ButtonComponent,
    InputComponent,
    PasswordInputComponent,
    PasswordUpdatedModalComponent,
    IconComponent,
    LoadingComponent,
    SegmentsComponent,
  ],
})
export class PersonalDetailsPage implements OnInit, OnDestroy {
  public user: {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    createdAt: string;
    createdBy?: UserSummary;
    status: UserStatus;
  } | null = null;

  public editing = {
    firstName: false,
    lastName: false,
    phoneNumber: false,
  };

  public readonly userForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    phoneNumber: new FormControl(''),
  });

  public readonly passwordForm = new FormGroup(
    {
      existingPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', PasswordValidators),
      confirmPassword: new FormControl('', PasswordValidators),
    },
    { validators: [confirmPasswordValidator] },
  );

  public page = 'details';
  public showPasswordUpdatedModal = false;
  public loading = false;
  public userStateLoading = true;
  public userStateSub$!: Subscription;

  private readonly loading$ = this.store
    .select(selectCognitoPending)
    .subscribe((loading) => (this.loading = loading));

  public constructor(private readonly store: Store) {}

  public async ngOnInit(): Promise<void> {
    this.userStateSub$ = this.store
      .select(selectUser)
      .subscribe(async ({ user, pending }) => {
        this.userStateLoading = pending;

        if (!pending && user) {
          const { activeOrganisation, profile } = user;

          this.user = {
            firstName: profile.firstName,
            lastName: profile.lastName,
            phoneNumber: profile.phoneNumber,
            email: profile.email,
            createdAt: formatDate(new Date(profile.createdAt * 1000)),
            createdBy: activeOrganisation.createdBySummary || undefined,
            status: activeOrganisation.status,
          };

          const { firstName, lastName, phoneNumber } = this.user;
          this.userForm.reset({ firstName, lastName, phoneNumber });
        }
      });
  }

  public ngOnDestroy(): void {
    this.loading$?.unsubscribe();
    this.userStateSub$?.unsubscribe();
  }

  public editMode(mode: 'firstName' | 'lastName' | 'phoneNumber'): void {
    this.editing = {
      firstName: false,
      lastName: false,
      phoneNumber: false,
    };

    this.editing[mode] = true;
    this.userForm.patchValue({
      [mode]: this.user![mode],
    });
  }

  public clearEditMode(): void {
    this.editing.firstName = false;
    this.editing.lastName = false;
    this.editing.phoneNumber = false;
  }

  public updateFirstName(): void {
    const { firstName } = this.userForm.value;

    if (!this.user || !firstName) {
      throw new Error();
    }

    this.store.dispatch(
      UserActions.updateProfile({
        payload: {
          firstName: firstName,
          lastName: this.user.lastName,
          phoneNumber: this.user.phoneNumber,
        },
      }),
    );

    this.clearEditMode();
  }

  public updateLastName(): void {
    const { lastName } = this.userForm.value;

    if (!this.user || !lastName) {
      throw new Error();
    }

    this.store.dispatch(
      UserActions.updateProfile({
        payload: {
          firstName: this.user.firstName,
          lastName: lastName,
          phoneNumber: this.user.phoneNumber,
        },
      }),
    );

    this.clearEditMode();
  }

  public updatePhoneNumber(): void {
    const { phoneNumber } = this.userForm.value;

    if (!this.user || !phoneNumber) {
      throw new Error();
    }

    this.store.dispatch(
      UserActions.updateProfile({
        payload: {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          phoneNumber: phoneNumber,
        },
      }),
    );

    this.clearEditMode();
  }

  public updatePassword(event: Event): void {
    event.preventDefault();

    const { existingPassword, password, confirmPassword } =
      this.passwordForm.value;

    if (!existingPassword || !password || !confirmPassword) {
      throw new Error();
    }

    if (password !== confirmPassword) {
      throw new Error('Passwords do not match');
    }

    this.store.dispatch(
      CognitoActions.updatePassword({
        credentials: {
          newPassword: password,
          oldPassword: existingPassword,
        },
      }),
    );

    this.showPasswordUpdatedModal = true;
  }

  public passwordUpdatedModalClosed(): void {
    this.page = 'details';
    this.passwordForm.reset();
    this.showPasswordUpdatedModal = false;
  }
}
