import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { IconComponent } from '../../../../components/icon/icon.component';
import { LoadingComponent } from '../../../../components/loading/loading.component';
import { ModalLayoutComponent } from '../../../../components/modal-layout/modal-layout.component';
import { selectCognito } from '../../../../store/selectors/cognito.selectors';

@Component({
  selector: 'app-password-updated-modal',
  standalone: true,
  templateUrl: './password-updated.component.html',
  imports: [
    LoadingComponent,
    CommonModule,
    IconComponent,
    ModalLayoutComponent,
  ],
})
export class PasswordUpdatedModalComponent {
  @Input()
  public show = false;

  @Output()
  public closed: EventEmitter<void> = new EventEmitter<void>();

  public cognitoState$ = this.store.pipe(select(selectCognito));

  public constructor(private readonly store: Store) {}
}
