<app-modal-layout
  (clickedOutside)="closed.emit()"
  *ngIf="show && (cognitoState$ | async); let cognitoState"
>
  <div class="rounded-2xl bg-white px-20 py-14">
    <ng-container *ngIf="cognitoState.pending; else responseView">
      <app-loading />
    </ng-container>

    <ng-template #responseView>
      <ng-container *ngIf="!cognitoState.error; else errorView">
        <div
          class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
        >
          <div class="h-16">
            <app-icon icon="success" />
          </div>
        </div>

        <div class="mt-6 text-center">
          <h2 i18n class="mb-2 text-2xl font-medium">
            Your password has been <br />
            successfully updated!
          </h2>
        </div>
      </ng-container>

      <ng-template #errorView>
        <div
          class="mx-auto flex h-40 w-40 items-center justify-center rounded-full bg-alpha-green"
        >
          <div class="h-16">
            <app-icon icon="warning" />
          </div>
        </div>

        <div class="mt-6 text-center">
          <h2 i18n class="mb-2 text-2xl font-medium">An error occurred</h2>
        </div>
      </ng-template>

      <div class="mt-8 flex flex-col">
        <button (click)="closed.emit()" i18n class="text-alpha-blue">
          Back to dashboard
        </button>
      </div>
    </ng-template>
  </div>
</app-modal-layout>
